import React, {useState, useEffect, useContext, useRef} from 'react';
import GeotabContext from '../contexts/Geotab';

const DevicesPage = () => {
  const [context] = useContext(GeotabContext);
  const [devices, setDevices] = useState([]);
  const [tpmsData, setTpmsData] = useState([]);
  const feedVersionRef = useRef(null);

  const { geotabApi, logger } = context;

  const startDate = new Date(new Date().setUTCHours(0,0,0,0) - 24*60*60*1000).toISOString();

  useEffect(() => {
    geotabApi.call('Get', {
      typeName: 'IoxAddOnStatus',
    }, (result) => {
      logger.log(`Loaded ${result.length} Iox Addons`);
      setDevices(result);
    }, (error) => {
      logger.error(error);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      geotabApi.call('GetFeed', {
        typeName: 'TextMessage',
        fromVersion: feedVersionRef.current,
        search: {
          fromDate: startDate,
        },
        resultsLimit: 1000,
      }, (result) => {
        logger.log(`Loaded ${result.data.length} TextMessages`);
        feedVersionRef.current = result.toVersion;
        setTpmsData([...tpmsData, ...result.data]);
      }, (error) => {
        logger.error(error);
      });
    }, 3000);
    return () => { clearInterval(interval); };

  }, []);
  return (
    <div>
      <div className="geotabPageHeader">
        <h1 className="geotabPageName">
          Devices
        </h1>
      </div>
      <div className="geotabSecondaryFill">
        <ul>
          {devices.map((device, ndx) => <li key={ndx}>{JSON.stringify(device)}</li>)}
        </ul>
      </div>
      <div className="tpms">
        <h1>TPMS Text Messages</h1>
        <div style={{ width: '100%', outline: "1px solid red", fontFamily: "monospace", fontSize: 12 }}>
          {tpmsData.map((t, ndx) => <div key={ndx}>{JSON.stringify(t)}</div>)}
        </div>
      </div>
    </div>
  );
};

export default DevicesPage;
